import { Component, Input, OnChanges, SimpleChange } from '@angular/core';

@Component({
  selector: 'app-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss'],
})
export class PasswordStrengthComponent implements OnChanges {
  @Input() text: any;
  errors: any = [
    {
      message: '8 characters or more',
      valid: false,
      regex: new RegExp('(^.{8,}$)')
    },
    // {
    //   message: 'one lowercase letter (a-z)',
    //   valid: false,
    //   regex: new RegExp('(?=.*[a-z])')
    // },
    {
      message: 'Uppercase letter (A-Z)',
      valid: false,
      regex: new RegExp('(?=.*[A-Z])')
    },
    // {
    //   message: 'one number (0-9)',
    //   valid: false,
    //   regex: new RegExp('(?=.*[0-9])')
    // },
    // {
    //   message: 'one special character (e.g. !"#$%&)',
    //   valid: false,
    //   regex: {
    //     test: function(val){

    //       const set1 = new Set('£€!"#$%&\'()*+,-./:;<=>?@[\]^_`{|}~');
    //       var set2 = new Set(val);
        
    //       var intersection = new Set(
    //         [...set1].filter(char => set2.has(char))
    //       );
        
    //       return intersection.size > 0;
    //     }
    //   }
    // },
  ];
  constructor() { }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    for(let i in this.errors){
      this.errors[i].valid = this.errors[i].regex.test(changes.text.currentValue);
    }
  }

}
